import React from 'react'
import classNames from 'classnames'

import DefaultLayout from '../../layout/default'

import AutomationMenu from '../../components/_V2/AutomationMenu'
import BusinessTypes from '../../components/_V2/BusinessTypes'
import SimpleFeatures from '../../components/_V2/SimpleFeatures'

import ProductPresentation from '../../pagesSections/avtomatizaciya-kafe/ProductPresentation'
import Presentation from '../../pagesSections/avtomatizaciya-kafe/ProductPresentation/assets/kafe-presentation.png'
import CtaForm from '../../pagesSections/index/CtaForm'

import { businessData } from '../../pages-data/_V2/common/business-types-compact-white'
import featuresData from '../../pages-data/_V2/automatization/cafe/features'

import pageStyles from '../../styles/_V2/pagesStyles/styles.module.scss'
import styles from '../styles/_V2/automation.module.scss'
import { useLocalizationContext } from "../../localization/useLocalizationContext";
import AccountingFeatures from "../../pagesSections/intergations/AccountingFeatures"
import ProductsFeatures from "../../pagesSections/intergations/ProductsFeatures"
import AdditionalServices from "../../pagesSections/index/AdditionalServices"

export default function PageAutomatization() {
  const localizationContext = useLocalizationContext();

  const title = 'Автоматизация кафе — облачная CRM система с программой учёта | Quick Resto'
  const description = 'CRM для автоматизации кафе Quick Resto ✔Онлайн-касса ✔Аналитика продаж и складской учет в облаке ✔Единая система обслуживания → Бесплатный пробный период 14 дней'
  const metaTags = [
    {
      name: 'description',
      content: description,
    },
    {
      property: 'og:url',
      content: 'https://quickresto.ru/automation/avtomatizaciya-kafe/',
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: description,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: `https://quickresto.ru${Presentation}`,
    },
  ]

  return (
    <DefaultLayout
      title={title}
      metaTags={metaTags}
      mainWrapperClassName={classNames(
			  pageStyles.pageWrapper,
			  pageStyles.pageGray,
			  pageStyles.automationPage,
      )}
      linkCanonical="https://quickresto.ru/automation/avtomatizaciya-kafe/"
      footerClassName={styles.automation__footer}
      schemaType="Product"
      schemaName={metaTags.find((tag) => tag.property === 'og:title').content}
      schemaDescription={metaTags.find((tag) => tag.property === 'og:description').content}
      schemaImage={metaTags.find((tag) => tag.property === 'og:image').content}
      schemaUrl={metaTags.find((tag) => tag.property === 'og:url').content}
    >
      <div
        className={classNames(
				  pageStyles.pageSection,
				  styles.automation__headline,
        )}
      >
        <ProductPresentation
          leftColumn={styles.automation__presentationLeftColumn}
          title={styles.automation__presentationTitle}
        />

        <AutomationMenu className={styles.automation__menu} />
      </div>

      <SimpleFeatures
        data={featuresData.filter((_, index) => index < 2)}
        className={pageStyles.pageSection}
      />

      <BusinessTypes
        key={localizationContext.locale}
        data={businessData(localizationContext.locale)}
        className={pageStyles.pageSection}
      />

      <CtaForm
        isFry={false}
        isWhiteBackground
        className={pageStyles.pageSection}
      />

      <SimpleFeatures
        data={featuresData.filter((_, index) => index < 6)}
        className={pageStyles.pageSection}
      />

      <AccountingFeatures className={pageStyles.pageSection} theme="white" />

      <ProductsFeatures className={pageStyles.pageSection} theme="white" />

      <AdditionalServices className={styles.additionalServices} theme="white" />

      <CtaForm
        isFry={false}
        isWhiteBackground
        className={pageStyles.pageSection}
      />
    </DefaultLayout>
  )
}
